<template >
    <div>







<h3 class="text-center">Company</h3>


        <b-card>
       
      <b-card-header> Filter </b-card-header>
      
      <b-card-body>
        <b-row>
          <b-col>
            <label for="datepicker-full-width">From</label>
            <b-form-datepicker
              id="datepicker-full-width"
              v-model="fromDate"
              menu-class="w-100"
              calendar-width="100%"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <b-col>
            <label for="datepicker-full">To</label>
            <b-form-datepicker
              id="datepicker-full"
              v-model="toDate"
              menu-class="w-100"
              calendar-width="100%"
              class="mb-2"
            ></b-form-datepicker>
          </b-col>
          <div class="text-center">
            <b-button @click="filterByDate" variant="primery">filter</b-button>
          </div>
        </b-row>
      </b-card-body>
    </b-card>
    <b-button @click="downloadFile()">export csv</b-button>

<p class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem"
          v-if="loading"
          label="Large Spinner"
        ></b-spinner>
      </p>

      
        <b-table :items="companies" :fields="fields" striped responsive="sm">
      <template #cell(show_details)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
        </b-button>

     
      </template>

      <template #row-details="row">
        <b-card>
          <b-row class="mb-12">
            
            <h3 class="text-center">{{ row.item.name }}</h3>
            
            <b-col sm="6" class="text-sm-left">
              <p>Email: {{ row.item.email }}</p>
              <p>Phone: {{ row.item.phone }}</p>
               <p>Head Office: {{ row.item.head_office }}</p>
              <p v-if="row.item.website">Website:
                <a v-if="row.item.website.toString().startsWith('http')" :href="row.item.website">{{ row.item.website }}</a>
                <a v-if="!row.item.website.toString().startsWith('http')" :href="'https://'+row.item.website">{{ row.item.website }}</a>
                </p>
              </b-col>
            
            <b-col sm="6" class="text-sm-left">
              <p> <b>Service</b><br> {{ row.item.about_service }}</p>
              <p> <b>Othe Information</b><br> {{ row.item.other_information }}</p>
              </b-col>
            
           
            
          </b-row>

          

          <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
        </b-card>
      </template>
    </b-table>
    </div>
</template>
<script>
import { getDataFilterByDate, getFields } from '../../assets/js/service'
    import exportFromJSON from "export-from-json";
export default {
    data() {
        return {
            companies:null,
            fields: ['name', 'email', 'phone', 'show_details'],
            loading:false,
        }
    },
    mounted() {
        let token = localStorage.getItem('token');
        this.$store.commit("set", ["loading", true]);
        getFields('Companies',token).then(resp=>{
          this.$store.commit("set", ["loading", false]);
            this.companies = resp.data.map(company => {
              company.isActive = false;
              return company
            });
        })
    },
    methods: {
  
         downloadFile() {
      const data = this.companies;
      const fileName = "Companies";
      const exportType = exportFromJSON.types.csv;

      if (data) exportFromJSON({ data, fileName, exportType });
    },
          filterByDate() {
      this.filterdData = true;
      let token = localStorage.getItem("token");
      getDataFilterByDate(
        "Companies",this.toDate,this.fromDate,
        token
      ).then((resp) => {
        console.log(resp);
        this.companies = resp.data;
      });
    },
    },
}
</script>
<style >
    
</style>